@import '/src/styles/_variables.scss';

.square {
    width: 20px;
    height: 10px;
    margin-right: 5px;
    border-radius: 2px;

    &.devis {
        background-color: $light-green;
    }

    &.simulation {
        background-color: $base-green;
    }
}

.kpiNumbers {
    margin-right: 20px;
    width: 260px;
}