@import '/src/styles/_variables.scss';

.forgottenPassword {
    cursor: pointer;
    margin-top: 30px;
    color: $lightgrey;
    font-family: 'Montserrat-Regular';
    font-size: 12px;
}

.loginInput {
    border: 1px solid $gray;
    border-radius: 10px;
    height: 40px;
    width: 300px;
    padding-left: 50px;
    font-family: 'Montserrat-SemiBold';
    color: $grey;
    font-size: 14px;
}

.loginInput:focus {
    border: 2px solid $base-green;
}