@import '/src/styles/_variables.scss';

.signOutSquare {
    background-color: $base-green;
    border-radius: 5px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
}

.greenSquare {
    height: 120px;
    width: 120px;
    padding: 10px;
    background-color: $base-green;
    border-radius: 10px;
}

.greenCircle {
    background-color: $base-green;
    border-radius: 15px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    margin-left: 20px;
}

.squareNumber {
    background-color: $base-green;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    color: $base-white;
    font-family: 'Montserrat-SemiBold';
    font-size: 20px;
}

.dashboard {
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.usersList {
    max-height: calc(100vh - 391px);
    min-height: 100px;
    overflow-y: auto;
    margin-top: 20px;
}

.userMask {
    width: 100%;
    height: calc(100vh - 391px);
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: 20px;
    border-radius: 15px;
}

.filterModal {
    width: 100%;
    background-color: $base-white;
    position: absolute;
    top: 0;
    margin-top: 20px;
    border-radius: 15px;
    padding: 20px;
}

.filterCheckbox {
    margin-right: 10px;
    width: 15px;
    height: 15px;
}