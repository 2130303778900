@import "mixins";

$base-white: white;
$base-green: #008d2c;
$light-green: #99d1ab;
$lightgrey: #b1b1b1;
$background-primary: #f9f9f9;
$gray: #e2e2e2;
$grey: #545454;
$text: #5e5e5e;
$delete: #ff6e6e;
$grey-button: #a8a8a8;
$brown: #c67e00;
$orange: #ffda99;