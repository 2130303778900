@import '/src/styles/_variables.scss';

input, button, submit { 
  border:none; 
} 

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="checkbox"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  outline: 0 none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="checkbox"] + label {
  cursor: pointer;
}

label {
  margin: 0;
}

.App {
    position: relative;
}

.children-centered {
  display: flex;
  align-items: center; 
  justify-content: center;
}

.buttonRectangle {
  border-radius: 10px;
  padding: 0px 30px 0px 30px;
  height: 40px;
  min-width: 180px;
  color: $base-white;
  font-size: 14px;
  font-family: 'Montserrat-SemiBold';

  &.green {
    background-color: $base-green;
  }

  &.grey {
    background-color: $grey-button;
  }

  &.red {
    background-color: $delete;
  }
}

.recharts-wrapper {
  position: absolute;
}

.container {
  max-width: 100%;
  min-height: 100vh;
  padding: 20px 50px;
}

@media (max-width: 1200px) {
    .container {
        max-height: 100%;
    }

    .dashboard {
        flex-direction: column;
        align-items: center;
    }
  
    div > .productsSection {
        margin-top: 50px;
        margin-bottom: 20px;
        width: 80%;
    }
  
    div > .usersSection {
        width: 80%;
    }
}

@media (max-width: 900px) {
    .kpis {
        flex-direction: column;
    }

    div > .kpiNumbers {
        align-self: center;
        margin-right: 0px;
    }

    .dashboardTitle {
        justify-content: center;
    }
}
.buttonRectangle:hover {
    color: $base-white;
}

.greyText {
  color: $grey;
  font-size: 14px;
  font-family: 'Montserrat-Bold';
  position: relative;
}

.greenUnderline {
  background-color: $light-green;
  height: 10px;
  width: 120%;
  top: 22px;
  position: relative;
  z-index: 0;
}

.basicText {
  color: $text;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
}

.bigWhiteText {
  color: $base-white;
  font-size: 20px;
  font-family: 'Montserrat-SemiBold';
}

.title {
  color: $base-green;
  font-size: 18px;
  font-family: 'Montserrat-Bold';
}

.iconPrimary {
  color: $base-green;
}

.backgroundPrimary {
  background-color: $background-primary;
}

.modalBackground {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  background-color: rgba($color: black, $alpha: 0.2);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
}

@media(max-height: 700px) {
  
  .modalBackground {
    position: absolute;
  }

  .modal-open {
    overflow-y: scroll;
  }
}

.modalWhite {
    background-color: $base-white;
    border-radius: 10px;
    width: 500px;
    padding: 20px;
}

.modalProduct {
  background-color: $base-white;
  border-radius: 10px;
  padding: 20px;
  width: 1000px;
}

@media(max-width: 1050px){
  .modalProduct {
    width: 700px;
  }
}

.basicInput {
  border: 1px solid $gray;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  height: 25px;
  margin-left: 20px;
  font-family: 'Montserrat-SemiBold';
  color: $grey;
  font-size: 14px;
  align-items: center;
  margin-top: 10px;
}

.basicInput:focus {
  border: 2px solid $grey;
}

.basicCheckbox {
  border: 1px solid $gray;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  height: 25px;
  margin-left: 20px;
  font-family: 'Montserrat-SemiBold';
  color: $grey;
  font-size: 14px;
  align-items: center;
  margin-top: 10px;
}

.errorMessage {
  font-family: 'Montserrat-Medium';
  color: $delete;
  font-size: 14px;
}

.loginContainer {
  background-color: $base-white;
  padding: 20px 50px 20px 50px;
  text-align: center;
  border-radius: 10px;
}

.textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

@-webkit-keyframes spinner /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner {
  -webkit-animation: spinner 2s linear infinite;
  -moz-animation: spinner 2s linear infinite;
  -ms-animation: spinner 2s linear infinite;
  -o-animation: spinner 2s linear infinite;
  animation: spinner 2s linear infinite;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'), url(fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url(fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}