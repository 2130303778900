@import '/src/styles/_variables.scss';

.userCard {
    background-color: $base-white;
    height: 60px;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 15px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.maskUserCard {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
}

.userActions {
    background-color: $base-green;
    padding-left: 10px;
    padding-right: 10px;
    width: 100px;
    height: 100%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-left: 30px solid $base-white;
    border-bottom: 60px solid transparent;
}

.userIcons {
    display: flex;
    justify-content: space-around;
    position: relative;
    right: 10px;
    width: 70px;
    top: 20px;
}

.estimateRectangle {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 30px;
    font-family: 'Montserrat-Regular';
    border-radius: 10px;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    word-wrap: break-word;

    &.green {
        background-color: $light-green;
        color: $base-green;
    }

    &.yellow {
        background-color: $orange;
        color: $brown;
    }
}

.userNameAndEstimate {
    margin-right: 30px;
    width: 250px;
}

@media (max-width: 1350px){
    div > .userNameAndEstimate {
        width: 150px;
    }
}

@media (max-width: 770px){
    div > .lastLoginOver770 {
        display: none;
    }

    div > .lastLoginUnder770 {
        display: flex;
    }

    .userCardMail {
        display: none;
    }
    
    div > .userNameAndEstimate {
        width: 100px;
        margin-right: 10px;
    }
}

@media (max-width: 650px){
    div > .estimateRectangle {
        padding-left: 10px;
        padding-right: 10px;
    }

    div > .estimateOver650 {
        display: none;
    }
    
    div > .estimateUnder650 {
        display: flex;
    }
}

.lastLoginOver770 {
    display: flex;
}

.lastLoginUnder770 {
    display: none;
}

.estimateOver650 {
    display: flex;
}

.estimateUnder650 {
    display: none;
}